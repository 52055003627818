import request, { IResponseEntity, IResponseList } from '@/api/request'
import { ISetAddressData, ICity, IGetRegionsResponseV5 } from '@/api/address/model'
import { IAddress } from '@/models/entities/address'

export const getAddresses = (params?: unknown) => {
  return request<IResponseList<IAddress>>({
    url: '/api/v5/address/addresses',
    method: 'GET',
    params,
  })
}

export const searchAddress = (params: unknown) => {
  return request<IResponseList<IAddress>>({
    url: '/api/v5/address/addresses/search',
    method: 'GET',
    params,
  })
}

export const createAddress = (data = {}) => {
  return request({
    url: '/api/v1/address/addresses',
    method: 'POST',
    data,
  })
}

export const setAddress = (data: ISetAddressData) => {
  return request({
    url: '/api/v5/address/addresses/set',
    method: 'POST',
    data,
  })
}

export const getAddress = (id: string | number) => {
  return request<IResponseEntity<Omit<IAddress, 'country' | 'regionData' | 'cityData'>>>({
    url: `/api/v5/address/addresses/${id}`,
    method: 'GET',
  })
}

export const createRegion = data => {
  return request({
    url: `/api/v5/address/regions`,
    method: 'POST',
    data,
  })
}

export const getRegions = (params = {}) => {
  return request({
    url: '/api/v5/address/regions',
    method: 'GET',
    params,
  })
}

export const getRegionsV5 = (data?: unknown): Promise<IGetRegionsResponseV5> => {
  return request({
    url: `/api/v5/address/regions`,
    method: 'GET',
    params: data,
  })
}

export const getRegion = (id = '') => {
  return request({
    url: `/api/v1/address/regions/${id}`,
    method: 'GET',
  })
}

export const updateRegion = (id = '', data) => {
  return request({
    url: `/api/v5/address/regions/${id}`,
    method: 'PUT',
    data,
  })
}

export const deleteRegion = (id = '') => {
  return request({
    url: `/api/v1/address/regions/${id}`,
    method: 'DELETE',
  })
}

export const getCities = (data?: unknown) => {
  return request<IResponseList<ICity>>({
    url: `/api/v5/address/cities`,
    method: 'GET',
    params: data,
  })
}

export const deleteCity = id => {
  return request({
    url: `/api/v1/address/cities/${id}`,
    method: 'DELETE',
  })
}

export const updateCity = (id, data) => {
  return request({
    url: `/api/v5/address/cities/${id}`,
    method: 'PUT',
    data,
  })
}

export const createCity = data => {
  return request({
    url: `/api/v5/address/cities`,
    method: 'POST',
    data,
  })
}
