import request from '@/api/request'
import { IFinancialParameterValue } from '@/models/entities/financialParameters.ts'

export const getParameterValues = (data?: unknown) => {
  return request<Record<'list', IFinancialParameterValue[]>>({
    url: `/api/v1/parameter_values`,
    method: 'GET',
    params: data,
  })
}
