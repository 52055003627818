import { isString, isObject, get, cloneDeep, isNil } from 'lodash'
import { Nullable } from '@/models/helpers'
import { useAuthStore } from '@/stores/auth'

const LANG = 'RU-ru'

// Извлечение в целевой объект значений из объекта с данными, если совпадают ключи
export function extractObjectValuesFromSource(target = {}, source = {}) {
  const clonedSourceData = cloneDeep(source)
  if (isObject(target) && isObject(source)) {
    Object.keys(target).forEach(key => {
      if (Object.prototype.hasOwnProperty.call(source, key)) {
        target[key] = clonedSourceData[key]
      }
    })
  }
}

// Создание строки из массива строк с разделителем
export function buildStringFromArray(strings = [], delimiter = ',') {
  return strings.filter(string => string).join(`${delimiter} `)
}

export function convertPriceForDisplay(value, isInput = false) {
  if (['number', 'string'].includes(typeof value) && !isNaN(Number(value))) {
    const convertedPrice = new Intl.NumberFormat(LANG, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(value)
    return convertedPrice
  } else {
    return isInput ? '' : '-'
  }
}

export function parseNumber(value) {
  if (typeof value === 'string') {
    let nums = value.replace(/\s/g, '')
    nums = nums.replace(/,/g, '.').trim()
    if (/^-?\d+(\.(\d+)?)?$/.test(nums)) {
      return Number(parseFloat(nums).toFixed(2))
    }
    return null
  }
  if (typeof value === 'number') {
    return value
  }
  return null
}

export function convertDate(dateString) {
  if (!isString(dateString)) return null
  const date = new Date(dateString)
  if (!isNaN(date) && date.toString() !== 'Invalid Date') {
    return new Date(dateString).toLocaleDateString(LANG)
  } else {
    return '-'
  }
}

/**
 * Заменяет значения совпадающих свойств объектов в массивах по ключу
 * @param array1
 * @param array2
 * @param objectProperty
 * @returns {unknown[]}
 */
export const mergeArraysByObjectProperty = (array1 = [], array2 = [], objectProperty = 'id') => {
  if (!Array.isArray(array1) || !Array.isArray(array2)) {
    return []
  }

  return Array.from(
    [...array1, ...array2]
      .filter(o => isObject(o) && Object.keys(o).length > 0)
      .reduce(
        (acc, curr) => acc.set(curr[objectProperty], { ...acc.get(curr[objectProperty]), ...curr }),
        new Map(),
      )
      .values(),
  )
}

/**
 * Возвращает расширение файла вида ".{ext}" из переданного имени файла.
 * @param filename
 * @returns {string|undefined}
 */
export const getExtensionFromFilename = (filename = '') => {
  if (!filename) return
  const extension = filename.split('.').pop().trim()
  return extension ? `.${extension}` : undefined
}

/**
 * Преобразует объект в FormData
 * @param payload
 * @returns {FormData}
 */
export const toFormData = (payload = {}) => {
  const formData = new FormData()
  for (const [key, value] of Object.entries(payload)) {
    if (typeof value === 'string') {
      formData.set(key, value)
    } else if (value instanceof File) {
      formData.set(key, value, value.name)
    } else {
      formData.set(key, JSON.stringify(value))
    }
  }
  return formData
}

//TODO: set type for data
export const downloadFileFromResponse = (
  data: unknown,
  fileName?: string,
  mime: string = 'text/plain',
) => {
  const blob = new Blob([data], { type: mime })
  const a = document.createElement('a')
  a.href = URL.createObjectURL(blob)
  a.download = fileName ?? ''
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
  URL.revokeObjectURL(a.href)
}

export const isNumeric = str => {
  if (typeof str !== 'string') {
    return false
  }
  return !isNaN(str) && !isNaN(parseFloat(str))
}

export const changeNumericValueType = (str: Nullable<string | number | (string | number)[]>) => {
  if (Array.isArray(str)) {
    return str.map(value => changeNumericValueType(value))
  }

  if (isNumeric(str)) {
    return parseInt(str)
  }
  return str
}

export function convertNumberForDisplay(value, isInput = false) {
  if (['number', 'string'].includes(typeof value) && !isNaN(Number(value))) {
    const convertedNumber = new Intl.NumberFormat(LANG).format(value)
    return convertedNumber
  } else {
    return isInput ? '' : '-'
  }
}

export const isLiteralObject = (object: unknown): object is object => {
  return Boolean(object) && object.constructor === Object
}

export const getColumnSortOrder = (initialSort, sortFieldName, convertSortField = true) => {
  if (!initialSort) return false
  const sortField = initialSort['filters.s.field']
  const sortDir = initialSort['filters.s.dir']
  const sortKey = Array.isArray(sortFieldName) ? sortFieldName[0] : sortFieldName
  const convertedFieldName =
    sortKey && convertSortField ? sortKey.replace(/([A-Z])/g, '_$1').toLowerCase() : sortKey
  if (sortField === convertedFieldName) {
    return `${sortDir}end`
  } else {
    return false
  }
}

export type Breadcrumb = {
  name: string
  text: {
    type: string
    options: {
      value?: string
      path?: string
      field?: string
      param?: string
      map?: Record<string, unknown>
    }
  }
  to?: {
    name: string
  }
}

export function setPageTitle(pathBreadcurms: Breadcrumb[]): void {
  document.title = getTitleNameFromBreadcrumbs(pathBreadcurms)
}

function getTitleNameFromBreadcrumbs(breadcrumbs: Breadcrumb[]): string {
  let title = 'HG3'
  if (breadcrumbs && breadcrumbs.length) {
    let isPreviousBreadcrumbHadText: boolean = false
    breadcrumbs.forEach((breadcrumb: Breadcrumb, key) => {
      const text: string = breadcrumb.text?.options?.value || ''
      if (!isPreviousBreadcrumbHadText && text) {
        isPreviousBreadcrumbHadText = true
        title += ' - '
      }
      if (key === 0 || !text) {
        title += text
      } else {
        title += ` / ${text}`
      }
    })
  }
  return title
}

const placeholderRegex = /{\w+(\.\w+)*}/g
export function computeRoutePath(routePathTemplate: string, dataSource: Record<string, unknown>) {
  if (placeholderRegex.test(routePathTemplate)) {
    return convertingFunc(routePathTemplate, dataSource)
  }
  return routePathTemplate
}
function convertingFunc(routePathTemplate: string, dataSource: Record<string, unknown>) {
  let isSomeValuesNullish = false
  const preparedPath = routePathTemplate.replace(placeholderRegex, match => {
    let preparedKeyPath: string = match.replace(/{|}/g, '')
    const dataForReplacement = get(dataSource, preparedKeyPath.split('.'))
    isSomeValuesNullish = isNil(dataForReplacement)
    return dataForReplacement
  })
  return isSomeValuesNullish ? null : preparedPath
}
export const hasOrHasNotFilterValues = {
  list: [
    {
      id: 1,
      name: 'Есть',
      value: 'true',
    },
    {
      id: 2,
      name: 'Нет',
      value: 'false',
    },
  ],
}

export const yesOrNotFilterValues = {
  list: [
    {
      id: 1,
      name: 'Да',
      value: 'true',
    },
    {
      id: 2,
      name: 'Нет',
      value: 'false',
    },
  ],
}

export const activeOrInactiveValues = {
  list: [
    {
      id: 1,
      name: 'Действует',
      value: 'active',
    },
    {
      id: 2,
      name: 'Не действует',
      value: 'inactive',
    },
  ],
}

export function isValueStringOrNumber(value: unknown): boolean {
  return ['string', 'number'].includes(typeof value) && !Number.isNaN(value)
}

// TODO Вынести в утилзы или поставить uuid (обдумать командой)
export const generateId = () => Math.floor(Math.random() * 10e5)

export function getApiParams(widgetId: string) {
  const authStore = useAuthStore()
  return {
    contourId: Number(authStore?.currentProfile?.contourId || 0),
    roleId: String(authStore?.currentProfile?.roleId || '1'),
    widgetId,
  }
}
