import request from '@/api/request'
import { ISettings } from '@/models/entities/settings'

export const getSettings = () => {
  return request<ISettings>({
    url: `/api/v1/settings`,
    method: 'GET',
  })
}

export const setSettings = (data: ISettings) => {
  return request({
    url: `/api/v1/settings`,
    method: 'PUT',
    data,
  })
}
