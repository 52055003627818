import request, {
  IResponseEntity,
  IResponseList,
  IResponseListWithAggregations,
} from '@/api/request'
import {
  ISubjectV2,
  ISubjectAddressType,
  ISubjectPerson,
  ISubjectPersonCreate,
  ISubjectV1,
  ISubjectCreatedAddresses,
} from '@/api/subjects/models'
import {
  ICreateSubjectAddressPayload,
  IGetIdentifierTypeMatchingSearchParams,
  IGetSuggessionsListParams,
  ISearchSubject,
  ISearchSubjectParams,
  ISubjectIdentifierTypeMatching,
  ISubjectRole,
  ISubjectSuggession,
  ISubjectType,
  IUpdateSubjectAddressPayload,
} from '@/models/entities/subject'
import { ISubjectDocument, ISubjectDocumentCreate } from '@/models/entities/document'

export const getSubjectsList = params => {
  return request<IResponseList<ISubjectV2>>({
    url: '/api/v1/subject/subjects',
    method: 'GET',
    params,
  })
}

export const getSubjectsListV2 = (params?: unknown) => {
  return request<IResponseList<ISubjectV2>>({
    url: '/api/v2/subject/subjects',
    method: 'GET',
    params,
  })
}

export const searchSubjects = (params?: ISearchSubjectParams) => {
  return request<IResponseListWithAggregations<ISearchSubject>>({
    url: '/api/v1/subjects/search',
    method: 'GET',
    params,
  })
}

export const createSubject = (data: unknown) => {
  return request<IResponseEntity<ISubjectV2>>({
    url: '/api/v1/subject/subjects',
    method: 'POST',
    data,
  })
}

export const getSubject = (id: string | number) => {
  return request<IResponseEntity<ISubjectV1>>({
    url: `/api/v1/subject/subjects/${id}`,
    method: 'GET',
  })
}

export const getSuggessionsList = (params?: IGetSuggessionsListParams) => {
  return request<ISubjectSuggession>({
    url: '/api/v1/subject/subjects_suggessions',
    method: 'GET',
    params,
  })
}

export const getTypesList = (params?: unknown) => {
  return request<IResponseList<ISubjectType>>({
    url: '/api/v1/subject/types',
    method: 'GET',
    params,
  })
}

export const getType = id => {
  return request({
    url: `/api/subject/subjects/types/${id}`,
    method: 'GET',
  })
}

export const getSubtypesList = (params?: unknown) => {
  return request({
    url: '/api/v1/subject/subtypes',
    method: 'GET',
    params,
  })
}

export const getSubtype = id => {
  return request({
    url: `/api/v1/subject/subjects/subtypes/${id}`,
    method: 'GET',
  })
}

export const getRolesList = (params?: unknown) => {
  return request<IResponseList<ISubjectRole>>({
    url: '/api/v1/subject/roles',
    method: 'GET',
    params,
  })
}

export const getRole = id => {
  return request({
    url: `/api/subject/subjects/roles/${id}`,
    method: 'GET',
  })
}

export const getIdentifiersList = (params?: IGetIdentifierTypeMatchingSearchParams) => {
  return request<IResponseList<ISubjectIdentifierTypeMatching>>({
    url: '/api/v1/subject/identifier_type_matchings',
    method: 'GET',
    params,
  })
}

export const getSubjectIdentifiers = params => {
  return request({
    url: '/api/v1/subject/subject_identifiers',
    method: 'GET',
    params,
  })
}

export const getIdentifier = id => {
  return request({
    url: `/api/v1/subject/subjects/identifiers/${id}`,
    method: 'GET',
  })
}

export const getSubjectsAccounts = (id: string | number, params?: unknown) => {
  return request({
    url: `/api/external/v1/subject/${id}/accounts`,
    method: 'GET',
    params,
  })
}

export const createSubjectsAccount = data => {
  return request({
    url: `/api/external/v1/subject/account`,
    method: 'POST',
    data,
  })
}

export const updateSubjectsAccount = data => {
  const { subjectId, accountId, ...payload } = data
  return request({
    url: `/api/external/v1/subject/${subjectId}/account/${accountId}`,
    method: 'PUT',
    data: payload,
  })
}

export const deleteSubjectsAccount = data => {
  return request({
    url: `/api/external/v1/subject/${data.subjectId}/account/${data.accountId}`,
    method: 'DELETE',
  })
}

export const getSubjectsAddresses = (subjectId: number | string, params?: unknown) => {
  return request({
    url: `/api/v2/subject/${subjectId}/addresses`,
    method: 'GET',
    params,
  })
}

export const getSubjectsAddressTypes = (): Promise<{
  count: number
  list: ISubjectAddressType[]
  filters: unknown
}> => {
  return request({
    url: `/api/v1/subject/address_types`,
    method: 'GET',
  })
}

export const createSubjectAddress = (data: ICreateSubjectAddressPayload) => {
  return request({
    url: `/api/v1/subject/addresses`,
    method: 'POST',
    data,
  })
}

export const updateSubjectAddress = (
  subjectId: ISubjectV1['id'],
  outerAddressId: ISubjectCreatedAddresses['addressId'],
  data: IUpdateSubjectAddressPayload,
) => {
  return request({
    url: `/api/v2/subject/${subjectId}/addresses/${outerAddressId}`,
    method: 'PUT',
    data,
  })
}

export const deleteSubjectAddress = id => {
  return request({
    url: `/api/v1/subject/addresses/${id}`,
    method: 'DELETE',
  })
}

export const getSubjectPersons = (params?: unknown) => {
  return request<IResponseList<ISubjectPerson>>({
    url: `/api/v1/subject/persons`,
    method: 'GET',
    params,
  })
}

export const createSubjectPerson = (data: ISubjectPersonCreate) => {
  return request<IResponseEntity<ISubjectPerson>>({
    url: `/api/v1/subject/persons`,
    method: 'POST',
    data,
  })
}

export const deleteSubjectPerson = id => {
  return request({
    url: `/api/v1/subject/persons/${id}`,
    method: 'DELETE',
  })
}

export const getSubjectDocuments = params => {
  return request<IResponseList<ISubjectDocument>>({
    url: '/api/v1/subject/documents',
    method: 'GET',
    params,
  })
}

export const createSubjectDocument = (data: ISubjectDocumentCreate) => {
  return request<IResponseEntity<ISubjectDocument>>({
    url: '/api/v1/subject/documents',
    method: 'POST',
    data,
  })
}

export const deleteSubjectDocument = id => {
  return request({
    url: `/api/v1/subject/documents/${id}`,
    method: 'DELETE',
  })
}
