import { defineStore } from 'pinia'
import { ref, type Ref } from 'vue'
import api from '@/api'
import { deleteForwardRoute } from '@/utils/routerHelpers'
import { IContourConfiguration, ILoginRequestData, IProfile } from '@/models/auth'

export const useAuthStore = defineStore('auth', () => {
  const currentProfile: Ref<IProfile | null> = ref(null)

  const contourConfiguration: Ref<IContourConfiguration | null> = ref(null)

  function setIsAuth(value: boolean) {
    localStorage.setItem('isAuth', `${value}`)
  }

  function getIsAuth(): boolean {
    return localStorage.getItem('isAuth') === 'true'
  }

  function setCurrentProfile(profile: IProfile | null) {
    currentProfile.value = profile
  }

  function setContourConfiguration(configuration: IContourConfiguration | null): void {
    contourConfiguration.value = configuration
  }

  async function makeLogin(requestData: ILoginRequestData) {
    const response = await api.auth.login(requestData)
    setIsAuth(true)
    return response
  }

  async function makeLogout() {
    await api.auth.logout()
    setIsAuth(false)
    setCurrentProfile(null)
    deleteForwardRoute()
  }

  const isAppInitialazing = ref<boolean>(false)

  return {
    makeLogin,
    makeLogout,
    setIsAuth,
    getIsAuth,
    setCurrentProfile,
    setContourConfiguration,
    currentProfile,
    contourConfiguration,
    isAppInitialazing,
  }
})

export type AuthStore = ReturnType<typeof useAuthStore>
