import request from '@/api/request'
import { IContourConfiguration } from '@/models/entities/contour'
import { IProfile } from '@/models/auth'

export const login = (data: unknown) => {
  return request({
    url: '/api/v1/auth/login',
    method: 'POST',
    data,
  })
}

export const logout = () => {
  return request({
    url: '/api/v1/auth/logout',
    method: 'POST',
  })
}

export const setProfile = (data: unknown) => {
  return request({
    url: '/api/v1/auth/profile',
    method: 'POST',
    data,
  })
}

export const getProfile = () => {
  return request<IProfile>({
    url: '/api/v1/auth/profile',
    method: 'GET',
  })
}

export const getContourConfiguration = () => {
  return request<IContourConfiguration>({
    url: '/api/v1/configuration/mock',
    method: 'GET',
  })
}
